<template>
  <div class="main-header">
    <div class="main-header-left">
      <div class="global-search">
        <el-input v-model="search" placeholder="Search">
          <template #prepend>
            <img class="first" src="@/assets/icons/search.svg" alt="Search"
          /></template>
        </el-input>
      </div>
    </div>
    <div class="main-header-right">
      <router-link class="header-button" to="/chat">
        <img class="first" src="@/assets/icons/chat.svg" alt="Chat" />
      </router-link>
      <el-dropdown
        popper-class="fix-el-dropdown main-header-dropdown"
        trigger="click"
        ref="dropdownMainHeader"
      >
        <span class="el-dropdown-link">
          <user-avatar :user="currentUser" :srcAvatar="null" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <div class="header-user-info">
              <div class="line1">{{ currentUser.getFullName }}</div>
              <div class="line2">{{ currentUser.email }}</div>
            </div>
            <div class="header-links">
              <router-link to="/your-profile" @click="closeDropdown"
                >Your profile</router-link
              >
              <router-link to="#" @click="signOut">Sign out</router-link>
            </div>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
//import MenuAlt2 from '@/assets/sidebar/menu-alt-2.svg'
import UserAvatar from '@/components/UserAvatar.vue'
import { fetchData, urls } from '@/helpers/urls.js'
import User from '@/models/user.js'

export default {
  name: 'MainHeader',

  components: {
    UserAvatar,
  },

  data() {
    return {
      search: '',
    }
  },

  created() {
    this.search = this.$store.state.mainLayout.search
  },

  watch: {
    search() {
      console.log('1111', this.search)

      let mainLayout = this.$store.state.mainLayout
      mainLayout.search = this.search
      this.$store.commit('setMainLayout', mainLayout)

      if (this.search.length > 2) this.$router.push('/search')
      //if (!this.search) this.$router.go(-1) do not work correctly need more time for dev
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
  },

  methods: {
    closeDropdown() {
      if (!this.$refs?.dropdownMainHeader) return
      this.$refs.dropdownMainHeader.handleClose()
    },

    async signOut() {
      this.$store.commit('setIsLoading', true)

      try {
        await fetchData(urls.logout, 'POST')
        localStorage.removeItem('token')
        this.$store.commit('setUser', new User({}))
        this.$store.commit('setIsLoading', false)
        this.$router.push('/signin')
      } catch (error) {
        this.$store.commit('setIsLoading', false)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 0 0;
  align-self: stretch;
  height: inherit;

  .main-header-right {
    display: flex;
    align-items: center;

    .header-button {
      cursor: pointer;
      width: 24px;
      height: 24px;
      margin-right: 14px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .global-search {
    width: 402px;
    .el-input {
      background: #{$bg-gray};
      height: 37px;

      .el-input-group__prepend {
        background: #{$bg-gray};
        padding: 0 10px;
        padding-right: 7px;
      }

      .el-input-group__append {
        --el-input-border-color: #{$bg-primary};
        background-color: #{$bg-primary};
        color: white;
      }

      .el-input__wrapper {
        padding: 0;
        background-color: #{$bg-gray};
        box-shadow: 0 -5px 0px -4px #{$input-border-color} inset,
          -5px 0 0px -4px #{$input-border-color} inset,
          0 5px 0px -4px #{$input-border-color} inset;
      }
    }
  }
}

.main-header-dropdown {
  width: 224px;

  .header-user-info {
    padding: 12px 16px;
    line-height: 150%;
    border-bottom: 1px solid #{$bg-avatar-color};
    font-size: #{$base-font-size};
    .line1 {
      font-weight: 500;
    }
  }

  .header-links {
    line-height: 150%;
    font-size: #{$base-font-size};

    a {
      color: #{$font-gray};
      display: block;
      padding: 12px 16px;
      &:last-of-type {
        padding-top: 0;
      }
    }
  }
}
</style>
