<template>
  <div class="left-sidebar">
    <div
      class="menu-item-link menu-button"
      :class="{ 'short-sidebar': isShortSidebar }"
    >
      <div class="main-menu-item" @click="onClickMenuButton">
        <div class="icon">
          <img :src="menuButton.icon" :alt="menuButton.text" />
        </div>
        <div class="text" v-if="!isShortSidebar">{{ menuButton.text }}</div>
      </div>
    </div>
    <template v-for="(item, key) in menuItems" :key="key">
      <router-link
        class="menu-item-link"
        :class="{
          'router-link-active': item.isActive,
          'short-sidebar': isShortSidebar,
          [item.classes]: item.classes,
        }"
        v-if="item.isVisible"
        :to="item.to"
      >
        <div class="main-menu-item">
          <div class="icon">
            <img :src="item.icon" :alt="item.text" />
          </div>
          <div class="text" v-if="!isShortSidebar">{{ item.text }}</div>
        </div>
      </router-link>
      <div
        class="separator"
        v-if="item.text === 'Reviews' || item.text === 'Help/FAQ'"
      ></div>
    </template>
  </div>
</template>

<script>
import MenuAlt2 from '@/assets/sidebar/menu-alt-2.svg'
import Home from '@/assets/sidebar/home.svg'
import Customers from '@/assets/sidebar/user-group.svg'
import Experts from '@/assets/sidebar/variant11.svg'
import Quotes from '@/assets/sidebar/document-text.svg'
import Invoices from '@/assets/sidebar/clipboard-list.svg'
import PaymentSummaries from '@/assets/sidebar/credit-card.svg'
import Reviews from '@/assets/sidebar/star.svg'
import Colleagues from '@/assets/sidebar/identification.svg'
import Templates from '@/assets/sidebar/view-boards.svg'
import JobTypes from '@/assets/sidebar/briefcase.svg'
import HelpFAQ from '@/assets/sidebar/variant2.svg'
import SdebarItem from '@/models/sidebar-item.js'

export default {
  name: 'Sidebar',
  data() {
    return {
      menuButton: new SdebarItem({
        text: 'Menu',
        icon: MenuAlt2,
      }),

      menuItems: [
        new SdebarItem({
          to: '/',
          text: 'Home',
          icon: Home,
        }),
        new SdebarItem({
          to: '/customers',
          text: 'Customers',
          icon: Customers,
        }),
        new SdebarItem({
          to: '/experts',
          text: 'Experts',
          icon: Experts,
        }),
        new SdebarItem({
          to: '/quotes',
          text: 'Quotes',
          icon: Quotes,
        }),
        new SdebarItem({
          to: '/invoices',
          text: 'Invoices',
          icon: Invoices,
        }),
        new SdebarItem({
          to: '/payment-summaries',
          text: 'Payment summaries',
          icon: PaymentSummaries,
        }),
        new SdebarItem({
          to: '/reviews',
          text: 'Reviews',
          icon: Reviews,
        }),
        new SdebarItem({
          to: '/collegues',
          text: 'Colleagues',
          icon: Colleagues,
        }),
        new SdebarItem({
          to: '/templates',
          text: 'Templates',
          icon: Templates,
        }),
        new SdebarItem({
          to: '/job-types',
          text: 'Job types',
          icon: JobTypes,
        }),
        new SdebarItem({
          to: '/help',
          text: 'Help/FAQ',
          icon: HelpFAQ,
        }),
      ],
    }
  },

  computed: {
    isShortSidebar() {
      return this.$store.state.mainLayout.isShortSidebar
    },
  },

  methods: {
    onClickMenuButton() {
      let mainLayout = this.$store.state.mainLayout
      mainLayout.isShortSidebar = !mainLayout.isShortSidebar

      this.$store.commit('setMainLayout', mainLayout)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.left-sidebar {
  padding: 13px 8px;
  .separator {
    display: block;
    margin: 15px -8px;
    background: #e5e7eb;
    height: 1px;
  }

  .menu-item-link {
    cursor: pointer;
    display: block;
    padding: 8px 6px;
    border-radius: 8px;
    margin-bottom: 2px;
    &.router-link-active,
    &:hover {
      background: #f3f4f6;
      img {
        transition: 0.3s ease-in;
        filter: invert(46%) sepia(8%) saturate(2931%) hue-rotate(223deg)
          brightness(19%) contrast(100%);
      }
    }

    &.menu-button {
      &:hover {
        background: none !important;
      }
      .main-menu-item {
        .icon {
          img {
            width: 32px !important;
            height: 32px !important;
          }
        }
      }
    }

    .main-menu-item {
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;

        img {
          transition: 0.3s ease-out;
          width: 24px;
          height: 24px;
        }
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        margin-left: 12px;
      }
    }

    &.short-sidebar:not(.menu-button) {
      display: flex;
      justify-content: center;
      border-radius: 0;
      margin-left: -7px;
      margin-right: -8px;
      &.router-link-active,
      &:hover {
        position: relative;
        background: #f3f4f6;
        &::before {
          content: ' ';
          display: block;
          width: 3px;
          height: 32px;
          position: absolute;
          top: 4px;
          left: -1px;
          border-radius: 0px 2px 2px 0px;
          background: $font-base;
        }
        img {
          transition: 0.3s ease-in;
          filter: invert(46%) sepia(8%) saturate(2931%) hue-rotate(223deg)
            brightness(19%) contrast(100%);
        }
      }
    }
  }
}
</style>
