<template>
  <el-avatar class="user-avatar hand" :style="styles">{{ calcName }}</el-avatar>
</template>

<script>
import User from '@/models/user.js'
export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: User,
      required: true,
    },

    size: {
      default: 32,
    },
  },

  computed: {
    calcName() {
      return this.user.getShortName()
    },
    styles() {
      return `min-width:${this.size}px; min-height:${this.size}px; width:${this.size}px; height:${this.size}px;`
    },
  },
}
</script>
