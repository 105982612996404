import { createStore } from 'vuex'
import User from '@/models/user.js'

export default createStore({
  state: {
    token: null,
    mainLayout: {
      search: '',
      isShortSidebar: false,
    },
    currentUser: new User({}),
    isLoading: false,
    pusher: null,

    selectedChatId: null,
    chats: [],
    messages: [],
  },
  getters: {},
  mutations: {
    setToken(state, data) {
      state.token = data
    },
    setUser(state, data) {
      state.currentUser = data
    },
    setIsLoading(state, data) {
      state.isLoading = data
    },
    setMainLayout(state, data) {
      state.mainLayout = data
    },
    setPusher(state, data) {
      state.pusher = data
    },
    setChats(state, data) {
      state.chats = data
    },
    setMessages(state, data) {
      state.messages = data
    },
    setSelectedChatId(state, data) {
      state.selectedChatId = data
    },
  },
  actions: {},
  modules: {},
})
