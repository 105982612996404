<template>
  <component :is="layout">
    <router-view />
  </component>
  <notifications position="bottom right" />
  <loading />
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'
import MainLayout from '@/layouts/MainLayout'
import Loading from '@/components/Loading.vue'

export default {
  components: {
    EmptyLayout,
    MainLayout,
    Loading,
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout'
    },
  },
}
</script>
