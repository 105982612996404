import '@/styles/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification'
import VCalendar from 'v-calendar'
import 'v-calendar/style.css'

import {
  ElButton,
  ElInput,
  ElCheckbox,
  ElContainer,
  ElAside,
  ElScrollbar,
  ElHeader,
  ElMain,
  ElAvatar,
  ElDropdown,
  ElDialog,
  ElSelect,
  ElOption,
  ElDrawer,
  ElCollapse,
  ElCollapseItem,
  ElTag,
  ElCol,
  ElRow,
  ElSwitch,
  ElDatePicker,
  ElTimePicker,
  ElRadioGroup,
  ElRadio,
  ElUpload,
} from 'element-plus'
import 'element-plus/dist/index.css'
import '@/styles/element-override/index.scss'

createApp(App)
  .use(store)
  .use(router)
  .use(Notifications)
  .use(ElButton)
  .use(ElInput)
  .use(ElCheckbox)
  .use(ElContainer)
  .use(ElAside)
  .use(ElScrollbar)
  .use(ElHeader)
  .use(ElMain)
  .use(ElAvatar)
  .use(ElDropdown)
  .use(ElDialog)
  .use(ElSelect)
  .use(ElOption)
  .use(ElDrawer)
  .use(ElCollapse)
  .use(ElCollapseItem)
  .use(ElTag)
  .use(ElCol)
  .use(ElRow)
  .use(ElSwitch)
  .use(ElDatePicker)
  .use(ElTimePicker)
  .use(ElRadioGroup)
  .use(ElRadio)
  .use(ElUpload)
  .use(VCalendar, {})
  .mount('#app')
