import User from '@/models/user'
export default class Chat {
  job_id = null
  job_name = null
  full_name = null
  message = null
  created_at = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }

  get jobNameAvatar() {
    let user = new User({
      first_name: ' ',
      last_name: ' ',
    })

    if (!this.full_name) return user

    let splettedString = this.job_name.split(' ')
    user.first_name = splettedString[0] || ' '
    user.last_name = splettedString[1] || ' '

    return user
  }

  get formattedTime() {
    if (!this.created_at) return ''

    let isToday = () => {
      let today = new Date()
      let date = new Date(this.created_at)
      return today.toDateString() === date.toDateString()
    }

    let dateTime = this.created_at.split('T')
    let date = dateTime[0]
    let time = dateTime[1].split('.')[0].split(':').slice(0, 2).join(':')

    if (isToday()) return time
    return date
  }
}

export class ChatMessage {
  id = null
  full_name = null
  first_name = null
  last_name = null
  message = null
  created_at = null
  updated_at = null
  file = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }

  get user() {
    let user = new User({
      first_name: this.first_name || ' ',
      last_name: this.last_name || ' ',
    })

    return user
  }

  get getShortName() {
    return `${(this.first_name || '')[0]}${(this.last_name || '')[0]}`
  }

  get getDateOrToday() {
    if (!this.created_at) return ''

    let isToday = () => {
      let today = new Date()
      let date = new Date(this.created_at)
      return today.toDateString() === date.toDateString()
    }

    if (isToday()) return 'Today'
    return this.created_at.split('T')[0]
  }

  get formattedTime() {
    if (!this.created_at) return ''

    let isToday = () => {
      let today = new Date()
      let date = new Date(this.created_at)
      return today.toDateString() === date.toDateString()
    }

    let dateTime = this.created_at.split('T')
    let date = dateTime[0]
    let time = dateTime[1].split('.')[0].split(':').slice(0, 2).join(':')

    if (isToday()) return time
    return date + ' ' + time
  }
}
