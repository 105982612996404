import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: 'main', isPublic: true },
    component: () => import('../views/AboutView.vue'),
  },

  {
    path: '/signin',
    name: 'Sign in',
    meta: { layout: 'empty', isPublic: true },
    component: () => import('../views/SignInView.vue'),
  },

  {
    path: '/forgot-password',
    name: 'Forgot your password',
    meta: { layout: 'empty', isPublic: true },
    component: () => import('../views/ForgotPasswordView.vue'),
  },

  {
    path: '/reset-password',
    name: 'Forgot your password-token',
    meta: { layout: 'empty', isPublic: true },
    component: () => import('../views/ChangePasswordTokenView.vue'),
  },

  {
    path: '/change-password',
    name: 'Change password',
    meta: { layout: 'empty', isPublic: true },
    component: () => import('../views/ChangePasswordView.vue'),
  },

  {
    path: '/your-profile',
    name: 'Your profile',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/YourProfileView.vue'),
  },

  {
    path: '/collegues',
    name: 'Collegues',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/ColleaguesView.vue'),
  },

  {
    path: '/templates',
    name: 'Templates',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/TemplatesView.vue'),
  },

  {
    path: '/job-types',
    name: 'Job types',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/JobTypesView.vue'),
  },

  {
    path: '/quotes',
    name: 'Quotes',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/QuotesView.vue'),
  },

  {
    path: '/customers',
    name: 'Customers',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/CustomersView.vue'),
  },

  {
    path: '/customer/:id?',
    name: 'Customer',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/CustomerView.vue'),
  },

  {
    path: '/reviews',
    name: 'Reviews',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/ReviewsView.vue'),
  },

  {
    path: '/jobs',
    name: 'Jobs',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/JobsView.vue'),
  },

  {
    path: '/job/:id?',
    name: 'Job',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/JobView.vue'),
  },

  {
    path: '/experts',
    name: 'Experts',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/ExpertsView.vue'),
  },

  {
    path: '/expert/:id?',
    name: 'Expert',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/ExpertView.vue'),
  },

  {
    path: '/invoices',
    name: 'Invoices',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/InvoicesView.vue'),
  },

  {
    path: '/payment-summaries',
    name: 'Payment summaries',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/PaymentSummariesView.vue'),
  },

  {
    path: '/help',
    name: 'Help/FAQ',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/HelpFAQView.vue'),
  },

  {
    path: '/search',
    name: 'Search',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/SearchView.vue'),
  },

  {
    path: '/chat',
    name: 'Chat',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/ChatView.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    meta: { layout: 'main', isPublic: false },
    component: () => import('../views/SearchView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const isPublic = to.meta?.isPublic || false
  let token = store.state.token

  if (!token) {
    token = localStorage.getItem('token')
    store.commit('setToken', token)
  }

  if (!token && !isPublic) {
    return next({ path: '/signin' })
  }

  next()
})

export default router
