const svr = `${process.env.VUE_APP_API_URL}/`
import { notify } from '@kyvg/vue3-notification' //https://github.com/kyvg/vue3-notification
import store from '../store'

export const urls = {
  login: `${svr}auth/login`,
  logout: `${svr}auth/logout`,
  profile: `${svr}profiles`,
  profileChangePassword: `${svr}profiles/change-password`,
  changePassword: `${svr}auth/password/reset`,
  forgotPassword: `${svr}auth/password/forgot`,
  resetPassword: `${svr}auth/password/reset/token`, //TODO: when Misha will add this route

  colleagues: `${svr}colleagues`,
  colleaguesFilters: `${svr}colleagues/filters`,
  colleaguesMetaDataForm: `${svr}colleagues/form`,
  colleaguesDelete: `${svr}colleagues`,

  templates: `${svr}templates`,
  templatesFilters: `${svr}templates/filters`,
  templatesMetaDataForm: `${svr}templates/form`,
  templatesDelete: `${svr}templates`,

  jobTypes: `${svr}job-types`,
  jobTypesFilters: `${svr}job-types/filters`,
  jobTypesMetaDataForm: `${svr}job-types/form`,
  jobTypesDelete: `${svr}job-types`,

  quotes: `${svr}quotes`,
  quotesFilters: `${svr}quotes/filters`,
  quotesMetaDataForm: `${svr}quotes/form`,
  quotesDelete: `${svr}quotes`,

  invoices: `${svr}invoices`,
  invoicesFilters: `${svr}invoices/filters`,
  invoicesMetaDataForm: `${svr}invoices/form`,
  invoicesDelete: `${svr}invoices`,

  customers: `${svr}customers`,
  customersFilters: `${svr}customers/filters`,
  customersMetaDataForm: `${svr}customers/form`,
  customersDelete: `${svr}customers`,
  customersNotificationSettings: `${svr}customers/notification-settings`,
  customersNotificationSettingsMetaDataForm: `${svr}customers/notification-settings/form`,
  customersCorporateInfo: `${svr}customers/corporate-info`,
  customersCorporateInfoMetaDataForm: `${svr}customers/corporate-info/form`,
  customersInvoiceAddress: `${svr}customers/invoice-address`,
  customersInvoiceAddressDataForm: `${svr}customers/invoice-address/form`,
  customersDebitInfo: `${svr}customers/debit-info`,
  customersDebitInfoDataForm: `${svr}customers/debit-info/form`,
  customersExecutionAddress: `${svr}customers/execution-address`,
  customersExecutionAddressMetaDataForm: `${svr}customers/execution-address/form`,
  customersAccessContact: `${svr}customers/access-contact`,
  customersAccessContactMetaDataForm: `${svr}customers/access-contact/form`,
  customersInfo: `${svr}customers/user`,
  customersInfoMetaDataForm: `${svr}customers/user/form`,
  customersJobs: `${svr}customers/jobs`,
  customersQuotes: `${svr}customers/quotes`,
  customersInvoices: `${svr}customers/invoices`,
  customersReviews: `${svr}customers/reviews`,

  experts: `${svr}experts`,
  expertsFilters: `${svr}experts/filters`,
  expertOne: `${svr}experts/profile`,
  expertsMetaDataForm: `${svr}experts/profile/form`,
  expertsDelete: `${svr}experts`,
  expertsAdminContact: `${svr}experts/admin-contact`,
  expertsAdminContactForm: `${svr}experts/admin-contact/form`,
  expertPrimaryAddress: `${svr}experts/primary-address`,
  expertPrimaryAddressForm: `${svr}experts/primary-address/form`,
  expertSecondaryAddress: `${svr}experts/secondary-address`,
  expertSecondaryAddressForm: `${svr}experts/secondary-address/form`,
  expertsNotifications: `${svr}experts/notification-setting`,
  expertsNotificationsForm: `${svr}experts/notification-setting/form`,
  expertsCompany: `${svr}experts/company-info`,
  expertsCompanyForm: `${svr}experts/company-info/form`,
  expertsJobs: `${svr}experts/jobs`,
  expertsQuotes: `${svr}experts/quotes`,
  expertsReviews: `${svr}experts/reviews`,
  expertsPaymentSummaries: `${svr}experts/payments`,
  expertsInfo: `${svr}experts/user`,
  expertsInfoMetaDataForm: `${svr}experts/user/form`,
  expertsDocuments: `${svr}experts/documents`,
  expertsDocumentsDataForm: `${svr}experts/documents/form`,
  expertsDocumentsFile: `${svr}experts/file`,

  reviews: `${svr}job-reviews`,
  reviewsFilters: `${svr}job-reviews/filters`,
  reviewsMetaDataForm: `${svr}job-reviews/form`,
  reviewsDelete: `${svr}job-reviews`,

  paymentSummaries: `${svr}payment-summaries`,
  paymentSummariesFilters: `${svr}payment-summaries/filters`,
  paymentSummariesMetaDataForm: `${svr}payment-summaries/form`,
  paymentSummariesDelete: `${svr}payment-summaries`,

  jobs: `${svr}jobs`,
  jobsFilters: `${svr}jobs/filters`,
  jobsMetaDataForm: `${svr}jobs/form`,
  jobsDelete: `${svr}jobs`,
  jobsJobTypes: `${svr}jobs/job-types`,
  jobsFixerTypes: `${svr}jobs/fixer-type`,
  jobsCustomerExecutionAddress: `${svr}jobs/job-customer-execution-address`,
  jobsAccessContact: `${svr}jobs/job-access-contacts`,
  jobsJobSummary: `${svr}jobs/job-summaries`,
  jobsJobDetails: `${svr}jobs/job-details`,
  jobsJobCustomerInfo: `${svr}jobs/job-customer-info`,
  jobsJobInspectionVisit: `${svr}job-inspections/list`,
  jobsJobQuotes: `${svr}quotes-by-job`,
  jobsJobRelevantExperts: `${svr}jobs/relevant-experts-by-job-type`,
  jobExecutionVisits: `${svr}job-execution/visits`,
  jobInvoices: `${svr}invoices/by-job`,
  jobPaymentSummaries: `${svr}payment-summaries/by-job`,
  jobJobReview: `${svr}jobs/review`,

  executionVisitOne: `${svr}job-execution`,
  executionVisits: `${svr}job-execution/list`,
  executionVisitsFilters: `${svr}job-execution/list/filters`,
  executionVisitsMetaDataForm: `${svr}job-execution/form`,
  executionVisitsDelete: `${svr}job-execution`,

  inspectionVisitOne: `${svr}job-inspections`,
  inspectionVisits: `${svr}job-inspections/list`,
  inspectionVisitsFilters: `${svr}job-inspections/list/filters`,
  inspectionVisitsMetaDataForm: `${svr}job-inspections/form`,
  inspectionVisitsDelete: `${svr}job-inspections`,

  helps: `${svr}helps`,
  helpsFilters: `${svr}helps/filters`,
  helpsMetaDataForm: `${svr}helps/form`,
  helpsDelete: `${svr}helps`,

  faq: `${svr}faqs`,
  faqFilters: `${svr}faqs/filters`,
  faqMetaDataForm: `${svr}faqs/form`,
  faqDelete: `${svr}faqs`,

  search: `${svr}search`,
  searchFilters: `${svr}search/filters`,
  reviewsDelete: ``,

  chats: `${svr}chats`,
  chatsInfo: `${svr}chats/info`,
  chatMessages: `${svr}chats/chat-messages`,
  sendMessage: `${svr}chats/send`,
  sendMessageFile: `${svr}chats/file`,

  jobsClone: `${svr}jobs/clone`,
  addCreditInvoice: `${svr}invoices/credit`,
}

export async function fetchData(url, type, body, query = '') {
  if (query) query = '?' + query

  const token = localStorage.getItem('token')
  let requestData = {
    method: type,
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  }

  if (!body) delete requestData.body
  if (!token) delete requestData.headers.Authorization

  try {
    const response = await fetch(url + query, requestData)

    if (response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/login'
      return null
    }

    if (response.status === 500 && type === 'DELETE')
      return (await response?.json?.()) || null
    if (response.status === 204) return null

    let result = (await response?.json?.()) || null

    /*
      404 - not found
      401 - Unauthenticated
      500 - Internal server error
      422 - Validation errors
    */

    /*
    404,401,500 = {'error'=>'message'}
    422 = {"errors": {"input_type_uuid": ["The selected additional_fields.0.input_type_uuid is invalid."]}}
   */

    if (response.status > 399) {
      let errorHTML = ''
      if (result?.errors) {
        for (const key in result.errors) {
          const item = result.errors[key]
          errorHTML += item.map((valueError) => {
            return `<div>${valueError}</div>`
          })
        }
      }

      notify({
        text:
          errorHTML || result?.error || result?.message || result || 'Error',
        type: 'error',
        duration: 3000,
      })
    }

    return result
  } catch (error) {
    notify({
      text: error,
      type: 'error',
      duration: 3000,
    })
  } finally {
    store.commit('setIsLoading', false)
  }
}

// function download(filename, text) {
//   var pom = document.createElement('a')
//   pom.setAttribute(
//     'href',
//     'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
//   )
//   pom.setAttribute('download', filename)
//   pom.click()
// }
