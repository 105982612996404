export default class SidebarItem {
  to = null
  text = null
  icon = null
  isActive = false
  isVisible = true
  classes = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }
}
