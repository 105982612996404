export default class User {
  id = null
  first_name = null
  last_name = null
  email = null
  phone = null

  constructor(data) {
    if (!data) return

    for (const key in data) {
      if (Object.keys(this).includes(key)) this[key] = data[key]
    }
  }

  get isEmpty() {
    return !this.email ? true : false
  }

  get getFullName() {
    return `${this.first_name} ${this.last_name}`
  }

  getShortName() {
    return `${(this.first_name || ' ')[0]}${(this.last_name || ' ')[0]}`
  }
}
