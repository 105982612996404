<template>
  <el-container class="main-layout" v-if="isMainDataLoaded">
    <el-aside class="left-el-side" :width="width">
      <el-scrollbar>
        <sidebar />
      </el-scrollbar>
    </el-aside>

    <el-container>
      <el-header height="63px" class="top-el-header">
        <MainHeader />
      </el-header>

      <el-main class="body">
        <el-scrollbar>
          <router-view></router-view>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import MainHeader from '@/components/MainHeader.vue'
import { fetchData, urls } from '@/helpers/urls.js'
import User from '@/models/user.js'
import Pusher from 'pusher-js'
import Chat from '@/models/chat'
import { ChatMessage } from '@/models/chat'

export default {
  name: 'MainLoyaut',
  data() {
    return {
      isMainDataLoaded: false,
    }
  },

  components: {
    Sidebar,
    MainHeader,
  },

  async created() {
    await this.setProfile()
    this.isMainDataLoaded = true
  },

  beforeUnmount() {
    this.pusher.unsubscribe('private-users.' + this.currentUser.id)
    this.pusher.unbind('messages', this.handPusherMessages)
    this.pusher.unbind('chats', this.handlePusherChats)
  },

  computed: {
    pusher() {
      return this.$store.state.pusher
    },

    currentUser() {
      return this.$store.state.currentUser
    },

    isShortSidebar() {
      return this.$store.state.mainLayout.isShortSidebar
    },

    width() {
      return this.isShortSidebar ? '60px' : '250px'
    },

    chats: {
      get() {
        return this.$store.state.chats
      },

      set(value) {
        this.$store.commit('setChats', value)
      },
    },

    messages: {
      get() {
        return this.$store.state.messages
      },
      set(value) {
        this.$store.commit('setMessages', value)
      },
    },

    selectedChatId: {
      get() {
        return this.$store.state.selectedChatId
      },
      // set(value) {
      //   this.$store.commit('setSelectedChatId', value)
      // },
    },
  },

  watch: {
    currentUser: {
      handler(newValue) {
        if (newValue?.id && !this.pusher) this.initPusher()
      },
      immediate: true,
    },
  },

  methods: {
    initPusher() {
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        authEndpoint: `${process.env.VUE_APP_BASE_API_URL}/broadcasting/auth`,
        auth: {
          headers: { Authorization: 'Bearer ' + this.$store.state.token },
        },
      })
      this.$store.commit('setPusher', pusher)

      this.pusher.subscribe('private-users.' + this.currentUser.id)

      this.pusher.bind('messages', this.handPusherMessages)
      this.pusher.bind('chats', this.handlePusherChats)
    },

    handPusherMessages(data) {
      console.log('handPusherMessages', data)
      if (!this.selectedChatId || !data?.message?.job_id) return

      if (this.selectedChatId === data.message.job_id)
        this.messages.unshift(new ChatMessage(data.message))

      /*
      {
    "message": {
        "id": "9aaaada3-265f-4fe9-884c-98ede68cfc7e",
        "job_id": "9a606d67-0734-4446-bb72-aa8ef06e8eb3",
        "user_id": "99ce984c-d4ce-4cca-bcb1-a0ebe8518d27",
        "message": "Can you help qqqqq?",
        "created_at": "2023-11-21T14:54:42.000000Z",
        "updated_at": "2023-11-21T14:54:42.000000Z"
    }
}
      */
    },

    handlePusherChats(data) {
      //  If we are not in the chat or there is no response, we do nothing
      if (!this.$route.path.includes('/chat') || !data?.chat) return

      const index = this.chats.findIndex(
        (chat) => chat.job_id === data.chat.job_id
      )

      if (index !== -1) {
        this.chats[index].created_at = data.chat.created_at
        this.chats[index].full_name = data.chat.full_name
        this.chats[index].message = data.chat.message
        this.chats[index].updated_at = data.chat.updated_at
      } else {
        this.chats.unshift(new Chat(data.chat))
      }
    },

    async setProfile() {
      const result = await fetchData(urls.profile, 'GET')
      if (result) this.$store.commit('setUser', new User(result.data))
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/variables.scss';
.left-el-side {
  border-right: 1px solid $main-border-color;
  background: white;
}

.top-el-header {
  border-bottom: 1px solid $main-border-color;
  background: white;
  padding: 0 20px;
}

.main-layout {
  height: 100dvh;
  background: #f9fafb;

  .body {
  }
}
</style>
